import React from 'react';

const Banner = () => {
  return (
    <section id="cover">
      <div id="cover-caption">
        <div className="container">
          <div className="col-sm-10 col-sm-offset-1" />
          <h1 className="display-1">Welcome to Call My Duty</h1>
          <p className="display-3">Gamble, win, gloat!</p>
        </div>
      </div>
    </section>
  );
};

export default Banner;
