import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAlert } from 'react-alert';

const Profile = (props) => {
  const alert = useAlert();
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [profile, setProfile] = useState(
    JSON.parse(window.localStorage.getItem('statsData')),
  );

  const submitChallenge = (friend) => {
    window.localStorage.setItem('opponentName', friend);
    props.lookUpOpponent();
    props.history.push('/challengeBoard');
  };

  const compareNames = (friend) => {
    for (let i = 0; i < profile.accountHeld.length; i += 1) {
      if (profile.accountHeld[i].id === friend.userName) {
        return true;
      }
    }
  };

  const handleInputChange = (mySetFunction, event) => {
    const { value } = event.target;
    mySetFunction(value);
  };

  const handleRefresh = () => {
    setLoading(true);
    axios
      .post(
        process.env.REACT_APP_REFRESH,
        {
          password: password,
        },
        {
          headers: {
            Authorization: window.localStorage.getItem('apiToken'),
          },
        },
      )
      .then((response) => {
        window.localStorage.setItem(
          'statsData',
          JSON.stringify(response.data.statsData),
        );
        setLoading(false);
        alert.success('Successful refresh');
        setPassword('');
        window.location.reload(false);
      })
      .catch((error) => {
        setLoading(false);
        alert.error(error.response.data.errorMessage);
      });
  };

  return (
    <div className="profile">
      <>
        <div>
          {loading ? (
            <div className="col-sm">
              <div>
                Checking Activision username, getting friends, retrieving
                account
              </div>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <div className="loading">
                <div className="loading-bar"></div>
                <div className="loading-bar"></div>
                <div className="loading-bar"></div>
                <div className="loading-bar"></div>
              </div>
            </div>
          ) : (
            <>
              <form>
                <div className="form-group">
                  <label htmlFor="email">
                    Activision password:
                    <input
                      className="form-control"
                      type="password"
                      name="password"
                      value={password}
                      onChange={(e) => handleInputChange(setPassword, e)}
                    />
                  </label>
                </div>
                <div className="form-group">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={handleRefresh}
                  >
                    Refresh friends
                  </button>
                </div>
              </form>
            </>
          )}
        </div>
        <h1 className="profile__username"> {props.user.username}'s Profile</h1>
        <h2 className="profile__friends">Friends</h2>
        {profile.friendData.map((friend) => (
          <div key={friend.userName} className="profile__friend">
            <span>{friend.userName}</span>

            {compareNames(friend) ? (
              <button
                className="profile__friendButton"
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  submitChallenge(friend.userName);
                }}
              >
                Challenge
              </button>
            ) : (
              <button
                disabled
                className="profile__friendButton"
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  submitChallenge(friend.userName);
                }}
              >
                Not signed up
              </button>
            )}
          </div>
        ))}
      </>
    </div>
  );
};

export default Profile;
