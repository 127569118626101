import React from 'react';
import QRCode from '../images/frame.png';

const XRPtipbot = () => {
  return (
    <div className="container">
      <div className="row2">
        <div className="col-sm" />
        <div className="col-sm">
          <div className="xrptipbot">
            <div className="text-center donate">
              <span>
                Feel like sending a sip my way{' '}
                <span role="img" aria-label="pintGlass">
                  🍺
                </span>
                ?
              </span>
            </div>
            <img src={QRCode} alt="QR code" />
            <br />
          </div>
        </div>
        <div className="col-sm" />
      </div>
    </div>
  );
};

export default XRPtipbot;
