import React, { useState } from 'react';
import Navbar from './components/navbar';
import Banner from './components/banner';
import AuthRoute from './components/auth-route';
import TokenManager from './utils/token-manager';
import './css/styles.css';
import Login from './pages/login';
import SignUp from './pages/sign-up';
import { Switch, Route, useHistory } from 'react-router-dom';
import Leaderboard from './pages/leaderboard';
import XRPtipbot from './components/xrptipbot';
import Profile from './pages/profile';
import ChallengeBoard from './pages/challengeBoard';
import BetSummary from './pages/betSummary';
import MarketBet from './pages/marketBet';
import NoStats from './components/no-stats';
import { useAlert } from 'react-alert';

const App = () => {
  const alert = useAlert();
  const history = useHistory();
  const [profile, setProfile] = useState(
    TokenManager.isTokenValid()
      ? JSON.parse(window.localStorage.getItem('statsData'))
      : null,
  );
  const [user, setUser] = useState(
    TokenManager.isTokenValid() ? TokenManager.getTokenPayload() : null,
  );

  const [opponent, setOpponent] = useState(
    TokenManager.isTokenValid()
      ? window.localStorage.getItem('opponentName')
      : null,
  );
  const [marketBet, setMarketBet] = useState(
    TokenManager.isTokenValid()
      ? window.localStorage.getItem('marketBet')
      : null,
  );

  const handleLogin = () => {
    setUser(TokenManager.getTokenPayload());
    setProfile(JSON.parse(window.localStorage.getItem('statsData')));
    history.push('/profile');
    alert.success('Successful login');
  };

  const handleLogout = () => {
    TokenManager.removeToken();
    setUser(null);
    alert.success('Successful logout');
  };

  const isLoggedIn = () => {
    return Boolean(user) && TokenManager.isTokenValid();
  };

  const lookUpOpponent = () => {
    setOpponent(window.localStorage.getItem('opponentName'));
  };

  const marketBetSet = () => {
    setMarketBet(window.localStorage.getItem('marketBet'));
  };

  const redirectToProfile = () => {
    history.push('/profile');
  };

  return (
    <div className="App">
      <Navbar isLoggedIn={isLoggedIn()} user={user} onLogout={handleLogout} />
      <Switch>
        <Route
          exact
          path="/login"
          render={(props) => <Login {...props} handleLogin={handleLogin} />}
        />
        <Route exact path="/sign-up" component={SignUp} />
        <Route exact path="/" component={Banner} />
        <AuthRoute
          exact
          path="/profile"
          component={Profile}
          authenticate={isLoggedIn}
          user={user}
          lookUpOpponent={lookUpOpponent}
        />
        <AuthRoute
          exact
          path="/betsummary"
          component={BetSummary}
          authenticate={isLoggedIn}
          user={user}
        />
        {opponent ? (
          <AuthRoute
            exact
            path="/challengeBoard"
            component={ChallengeBoard}
            authenticate={isLoggedIn}
            user={user}
            opponent={opponent}
            marketBet={marketBetSet}
          />
        ) : (
          <div>
            No player/market selected return to profile or player has no stats.
            <span>
              {' '}
              <button
                className="profile__friendButton"
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  redirectToProfile();
                }}
              >
                Return to profile
              </button>
            </span>
          </div>
        )}
        {opponent && marketBet ? (
          <AuthRoute
            exact
            path="/marketbet"
            component={MarketBet}
            authenticate={isLoggedIn}
            marketBet={marketBet}
            user={user}
            opponent={opponent}
          />
        ) : (
          <div>
            No player/market selected return to profile or player has no stats.
            <span>
              {' '}
              <button
                className="profile__friendButton"
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  redirectToProfile();
                }}
              >
                Return to profile
              </button>
            </span>
          </div>
        )}
        <AuthRoute
          exact
          path="/nostats"
          component={NoStats}
          authenticate={isLoggedIn}
        />
        <Route
          exact
          path="/leaderboard"
          render={(props) => <Leaderboard {...props} />}
        />
      </Switch>
      <XRPtipbot />
    </div>
  );
};

export default App;
