import React, { useState, useEffect } from 'react';

const ChallengeBoard = (props) => {
  const opponentName = window.localStorage.getItem('opponentName');
  const [opponentStats, setOpponentStats] = useState(null);
  const [profile, setProfile] = useState(
    JSON.parse(window.localStorage.getItem('statsData')),
  );

  useEffect(() => {
    if (
      profile.friendData.filter(
        (opponent) => opponent.userName === opponentName,
      )[0].battleRoyaleStats === undefined
    ) {
      props.history.push('nostats');
    } else {
      setOpponentStats(
        profile.friendData.filter(
          (opponent) => opponent.userName === opponentName,
        )[0].battleRoyaleStats.summary.all,
      );
    }
  }, []);

  const submitBet = (stat) => {
    window.localStorage.setItem('marketBet', stat);
    props.marketBet();
    props.history.push('/marketbet');
  };

  return (
    <>
      <div className="challengeBoard__title">
        Challenge the fool: {opponentName}
      </div>
      <div className="parentChallengeBoard">
        {profile?.recentGames ? (
          <>
            <div className="challengeBoard_YourStats">Your stats</div>
            <div className="challengeBoard_YourStatNumbers">
              <h2 className="profile__recentGames">Recent games (last 20)</h2>
              {Object.entries(profile.recentGames.summary.all).map((stat) => (
                <div className="profile__stat" key={`${stat[1]}+${stat[0]}`}>
                  {`${stat[0]}: ${stat[1]}`}
                </div>
              ))}
            </div>
          </>
        ) : (
          <div>You don't have any stats</div>
        )}

        <div className="challengeBoard_TheirStats">Their stats</div>
        <div className="challengeBoard_TheirStatNumbers">
          <h2 className="profile__recentGames">Recent games (last 20)</h2>
          {opponentStats &&
            Object.entries(opponentStats).map((stat) => (
              <div className="profile__stat" key={`${stat[1]}+${stat[0]}`}>
                {`${stat[0]}: ${stat[1]}`}{' '}
                <span>
                  <button
                    className="profile__friendButton"
                    type="submit"
                    onClick={(e) => {
                      e.preventDefault();
                      submitBet(stat[0]);
                    }}
                    key={`${stat[1]}+${stat[0]}+button`}
                  >
                    Gamble
                  </button>
                </span>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default ChallengeBoard;
