import React, { useState } from 'react';
import axios from 'axios';
import { useAlert } from 'react-alert';
import { useHistory } from 'react-router-dom';

const MarketBet = (props) => {
  const history = useHistory();
  const alert = useAlert();
  const [amount, setAmount] = useState(0);
  const [message, setMessage] = useState('');

  const handleInputChange = (mySetFunction, event) => {
    const { value } = event.target;
    mySetFunction(value);
  };

  const postBet = () => {
    const opponents = JSON.parse(window.localStorage.getItem('statsData'))
      .accountHeld;

    const opponentId = (opponentArray) => {
      for (let i = 0; i < opponentArray.length; i = +1) {
        if (opponentArray[i].id === props.opponent) {
          return opponentArray[i]._id;
        }
      }
    };

    axios
      .post(
        process.env.REACT_APP_POST_BET,
        {
          opponentId: opponentId(opponents),
          opponentUsername: props.opponent,
          betStatus: 'requested',
          market: props.marketBet,
          amount: amount,
          message: message,
        },
        {
          headers: {
            Authorization: window.localStorage.getItem('apiToken'),
          },
        },
      )
      .then((response) => {
        alert.success('Bet request sent');
        history.push('/betsummary');
      })
      .catch((error) => {
        alert.error(error.response.data.errorMessage);
      });
  };

  return (
    <div className="marketBetWrapper">
      <div className="marketBet__Summary">
        Bet is in the {props.marketBet} category against {props.opponent}
      </div>
      <form>
        <div className="marketBet__message">
          <label htmlFor="message">
            Message to opponent:
            <input
              className="form-control"
              type="text"
              name="message"
              value={message}
              onChange={(e) => handleInputChange(setMessage, e)}
            />
          </label>
        </div>
        <div className="marketBet__amount">
          <label htmlFor="email">
            Amount:
            <input
              className="form-control"
              type="number"
              name="amount"
              value={amount}
              onChange={(e) => handleInputChange(setAmount, e)}
            />
          </label>
        </div>
      </form>
      <div className="marketBet__Button">
        <button
          className="profile__friendButton"
          type="submit"
          onClick={(e) => {
            e.preventDefault();
            postBet();
          }}
        >
          Request bet
        </button>
      </div>
      <div className="marketBetLeft"></div>
      <div className="marketBetRight"></div>
    </div>
  );
};

export default MarketBet;
