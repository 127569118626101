import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAlert } from 'react-alert';

const BetSummary = (props) => {
  const alert = useAlert();
  const [loading, setLoading] = useState(false);
  const [betSummary, setBetSummary] = useState(null);
  const [awaitingApproval, setAWaitingApproval] = useState([]);
  const [awaitingApprovalOpponent, setAwaitingApprovalOpponent] = useState([]);
  const [approved, setApproved] = useState([]);
  const [completed, setCompleted] = useState([]);

  useEffect(() => {
    setLoading(true);
    axios
      .get(process.env.REACT_APP_GETBETS, {
        headers: {
          Authorization: window.localStorage.getItem('apiToken'),
        },
      })
      .then((response) => {
        if (response.data) {
          window.localStorage.setItem(
            'betSummary',
            JSON.stringify(response.data),
          );
          setBetSummary(response.data);
        }

        const betFiltering = () => {
          response.data.map((bet) => {
            if (
              bet.username === props.user.username &&
              bet.betStatus === 'requested'
            ) {
              setAwaitingApprovalOpponent((awaitingApprovalOpponent) => [
                ...awaitingApprovalOpponent,
                bet,
              ]);
            } else if (
              bet.username !== props.user.username &&
              bet.betStatus === 'requested'
            ) {
              setAWaitingApproval((awaitingApproval) => [
                ...awaitingApproval,
                bet,
              ]);
            } else if (bet.betStatus === 'approved') {
              setApproved((approved) => [...approved, bet]);
            } else if (bet.betStatus === 'completed') {
              setCompleted((completed) => [...completed, bet]);
            }
          });
        };

        betFiltering();
      })
      .catch((error) => {
        setLoading(false);
        alert.error(error.response.data.errorMessage);
      });
  }, []);

  const approveBet = (betId) => {
    axios
      .post(
        process.env.REACT_APP_APPROVE_BET,
        {
          betId: betId,
        },
        {
          headers: {
            Authorization: window.localStorage.getItem('apiToken'),
          },
        },
      )
      .then((response) => {
        window.location.reload(false);
      })
      .catch((error) => {
        alert.error(error.response.data.errorMessage);
      });
  };

  return (
    <div>
      {betSummary ? (
        <div>
          {awaitingApprovalOpponent.length ? (
            awaitingApprovalOpponent.map((bet) => {
              return (
                <div key={bet.username}>
                  <div>
                    <strong>Awaiting approval from your opponent bet</strong>
                  </div>
                  <div>
                    Bet between {bet.username} and {bet.opponentUsername} in the{' '}
                    {bet.market} market for {bet.amount} XRP
                  </div>
                  <div>Current status of the bet is: {bet.betStatus}</div>
                  <div>The message with the bet was: {bet.message}</div>
                </div>
              );
            })
          ) : (
            <div> No bets awaiting approval from your opponent</div>
          )}
          {awaitingApproval.length ? (
            awaitingApproval.map((bet) => {
              return (
                <div key={bet.username}>
                  <div>
                    <strong>Awaiting approval from you bets</strong>
                  </div>
                  <div>
                    Bet between {bet.username} and {bet.opponentUsername} in the{' '}
                    {bet.market} market for {bet.amount} XRP
                  </div>
                  <div>Current status of the bet is: {bet.betStatus}</div>
                  <div>The message with the bet was: {bet.message}</div>
                  <div>
                    <button
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        approveBet(bet._id);
                      }}
                    >
                      Approve bet
                    </button>
                  </div>
                </div>
              );
            })
          ) : (
            <div> No bets awaiting approval from you</div>
          )}
          {approved.length ? (
            approved.map((bet) => {
              return (
                <div key={bet.username}>
                  <div>
                    <strong>Approved bets</strong>
                  </div>
                  <div>
                    Bet between {bet.username} and {bet.opponentUsername} in the{' '}
                    {bet.market} market for {bet.amount} XRP
                  </div>
                  <div>Current status of the bet is: {bet.betStatus}</div>
                  <div>The message with the bet was: {bet.message}</div>
                </div>
              );
            })
          ) : (
            <div> No approved bets</div>
          )}
          {completed.length ? (
            completed.map((bet) => {
              return (
                <div key={bet.username}>
                  <div>
                    <strong>Completed bets</strong>
                  </div>
                  <div>
                    Bet between {bet.username} and {bet.opponentUsername} in the{' '}
                    {bet.market} market for {bet.amount} XRP
                  </div>
                  <div>Current status of the bet is: {bet.betStatus}</div>
                  <div>The message with the bet was: {bet.message}</div>
                </div>
              );
            })
          ) : (
            <div> No completed bets</div>
          )}
        </div>
      ) : (
        <div>You have no bets to display</div>
      )}
    </div>
  );
};

export default BetSummary;
