import React from 'react';

const NoStats = (props) => {
  return (
    <div>
      Player has no stats for Battle Royale
      <span>
        {' '}
        <button
          className="profile__friendButton"
          type="submit"
          onClick={(e) => {
            e.preventDefault();
            props.history.push('/profile');
          }}
        >
          Return to profile
        </button>
      </span>
    </div>
  );
};

export default NoStats;
