import React from 'react';
import axios from 'axios';

const styles = {
  flex: { display: 'flex' },
  flexItem: { flex: 1 },
};

class Leaderboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      leaders: [],
    };
  }

  componentDidMount() {
    this.getLeaders();
  }

  getLeaders() {
    axios
      .get('https://zerpwarz-api.herokuapp.com/users/leaderboard', {})
      .then((response) => {
        console.log(response);
        this.setState({ leaders: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    let number = 1;
    return (
      <div className="container text-center">
        <div className="row">
          <div className="col-sm" />
          <div className="col-sm">
            <div className="leaderboard">
              <div style={styles.flex}>
                <div style={styles.flexItem}>
                  <h2>The highest number of callmyduty wins</h2>
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Leaders</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.leaders.map((leader) => (
                        <tr key={leader}>
                          <th scope="row">{number++}</th>
                          <td>{leader}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm" />
        </div>
      </div>
    );
  }
}

export default Leaderboard;
