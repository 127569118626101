import React, { useState } from 'react';
import axios from 'axios';
import { useAlert } from 'react-alert';

const SignUp = (props) => {
  const alert = useAlert();
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [paymentId, setPaymentId] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleInputChange = (mySetFunction, event) => {
    const { value } = event.target;
    mySetFunction(value);
  };

  const handleSignUp = () => {
    setLoading(true);
    axios
      .post(process.env.REACT_APP_POST_SIGNUP, {
        id: username,
        email: email,
        password: password,
        paymentId: paymentId,
      })
      .then(() => {
        alert.success('Successful registration');
        props.history.push('/login');
      })
      .catch((error) => {
        setLoading(false);
        alert.error(error.response.data.errorMessage);
        setErrorMessage(error.response.data.errorMessage);
      });
  };

  return (
    <>
      {loading ? (
        <div className="container text-center">
          <div className="row">
            <div className="col-sm" />
            <div className="col-sm">
              <div>
                Checking Activision username, getting friends, creating account
              </div>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <div className="loading">
                <div className="loading-bar"></div>
                <div className="loading-bar"></div>
                <div className="loading-bar"></div>
                <div className="loading-bar"></div>
              </div>
            </div>
            <div className="col-sm" />
          </div>
        </div>
      ) : (
        <div className="container text-center">
          <div className="sign-up__mainblock">
            <div className="row">
              <div className="col-sm" />
              <div className="col-sm">
                <form>
                  <h1>Sign Up</h1>
                  <div className="form-group">
                    <label htmlFor="username">
                      Activision username:
                      <input
                        className="form-control"
                        type="text"
                        name="username"
                        value={username}
                        onChange={(e) => handleInputChange(setUsername, e)}
                      />
                    </label>
                    <div>
                      <label htmlFor="email">
                        Activision email:
                        <input
                          className="form-control"
                          type="email"
                          name="email"
                          value={email}
                          onChange={(e) => handleInputChange(setEmail, e)}
                        />
                      </label>
                    </div>
                    <div>
                      <label htmlFor="password">
                        Activision password:
                        <input
                          className="form-control"
                          type="password"
                          name="password"
                          value={password}
                          onChange={(e) => handleInputChange(setPassword, e)}
                        />
                      </label>
                    </div>
                    <div>
                      <label htmlFor="paymentId">
                        Payment Id:
                        <input
                          className="form-control"
                          type="text"
                          name="paymentId"
                          value={paymentId}
                          onChange={(e) => handleInputChange(setPaymentId, e)}
                        />
                      </label>
                    </div>
                  </div>
                  <div>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={handleSignUp}
                    >
                      Sign Up
                    </button>
                  </div>
                  {errorMessage && (
                    <div>
                      <span style={{ color: 'red', size: '2rem' }}>
                        {errorMessage}
                      </span>
                    </div>
                  )}
                </form>
              </div>
              <div className="col-sm" />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SignUp;
