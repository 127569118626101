import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useAlert } from 'react-alert';

import TokenManager from '../utils/token-manager';

const Login = (props) => {
  const alert = useAlert();
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleInputChange = (mySetFunction, event) => {
    const { value } = event.target;
    mySetFunction(value);
  };

  const handleLogin = () => {
    setLoading(true);
    axios
      .post(process.env.REACT_APP_POST_LOGIN, {
        id: username,
        email: email,
        password: password,
      })
      .then((response) => {
        TokenManager.setToken(response.data.token);
        window.localStorage.setItem(
          'statsData',
          JSON.stringify(response.data.statsData),
        );
        props.handleLogin();
      })
      .catch((error) => {
        setLoading(false);
        alert.error(error.response.data.errorMessage);
        setErrorMessage(error.response.data.errorMessage);
      });
  };

  return (
    <>
      {loading ? (
        <div className="container text-center">
          <div className="row">
            <div className="col-sm" />
            <div className="col-sm">
              <div>
                Checking Activision username, getting friends, retrieving
                account
              </div>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <div className="loading">
                <div className="loading-bar"></div>
                <div className="loading-bar"></div>
                <div className="loading-bar"></div>
                <div className="loading-bar"></div>
              </div>
            </div>
            <div className="col-sm" />
          </div>
        </div>
      ) : (
        <div className="container text-center">
          <div className="row">
            <div className="col-sm" />
            <div className="col-sm">
              <form>
                <h1>Login</h1>
                <div className="form-group">
                  <label htmlFor="username">
                    Activision username:
                    <input
                      className="form-control"
                      type="text"
                      name="username"
                      value={username}
                      onChange={(e) => handleInputChange(setUsername, e)}
                    />
                  </label>
                </div>
                <div className="form-group">
                  <label htmlFor="email">
                    Activision email:
                    <input
                      className="form-control"
                      type="email"
                      name="email"
                      value={email}
                      onChange={(e) => handleInputChange(setEmail, e)}
                    />
                  </label>
                </div>
                <div className="form-group">
                  <label htmlFor="password">
                    Activision password:
                    <input
                      className="form-control"
                      type="password"
                      name="password"
                      value={password}
                      onChange={(e) => handleInputChange(setPassword, e)}
                    />
                  </label>
                </div>
                <div className="form-group">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={handleLogin}
                  >
                    Login
                  </button>{' '}
                  or{' '}
                  <Link to="/sign-up">
                    <h6>Sign-up</h6>
                  </Link>
                </div>
                {errorMessage && (
                  <div>
                    <span style={{ color: 'red', size: '2rem' }}>
                      {errorMessage}
                    </span>
                  </div>
                )}
              </form>
            </div>
            <div className="col-sm" />
          </div>
        </div>
      )}
    </>
  );
};

export default Login;
